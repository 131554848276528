.logostate .header-brand-img{
    height: 50px;
}
#statedash button.slick-arrow {
    display: none !important;
}

#statedash .logout {
    font-family: 'Teko';
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 1;
    color: #ff0000;
}
.lineheight1{
    line-height: 1;
}
.bg-cust {
    background-color: #e2e2e2 !important;
    
}
.slick-list .card .card-body{
    padding: 10px;
}
.slick-list .card .card-body .avatar-lg{
    display: none;
}
.tx-capitalize {
    text-transform: capitalize;
}   
.page-header{
    min-height: 35px;
}
.tx-teko{
    font-family: "Teko", sans-serif !important;
}
.tx-amarnath{
    font-family: 'Amaranth' !important;
}
.tx-roboto{
    font-family: 'Roboto' !important;
}
.btn.tx-teko {
    font-size: 1.25rem;
    line-height: 1.35;
    padding: 0.55rem 0.75rem;
}
.lineheight1{
    line-height: 1;
}
.navitxt {
    padding: 15px 10px;
    border: 1px solid #c1c1c1;
    margin-bottom: 10px;
    display: flex;
    border-radius: 5px;
}
.navitxt .me-2 {
    margin-right: 0.75rem !important;
}
.navitxt.active,
.navitxt:hover {
    border: 1px solid #19b159;
    background: #eafbea !important;
    cursor: pointer;
}
.navitxt i.fa, 
.navitxt h3 span {
    color: rgb(42 41 41 / 54%);
}

.navitxt.active i.fa,
.navitxt.active h3 span,
.navitxt:hover i.fa,
.navitxt:hover h3 span
{
    color: #19b159;
} 



table tr td a.btn.btn-sm{
    min-width: 70px !important;
}
.eventslist .navitxt {
    border: 1px solid #e2e2e2;
    background: #e2e2e2;
    cursor: pointer;
}
td img.tablelogo{
    width: 42px;
    height: auto;
}

.topheadnav .navitxt {
    margin-bottom: 0px;
    align-items: center;
    min-height: 57px;
    text-align: center;
    padding: 0.5rem 0.75rem !important;
}

.topheadnav .card{
    border-radius: 0px;
    margin-bottom: 0px;
}

.topheadnav .card .card-header{
    padding: 0px;
}

.border{
    border: 1px solid;
}
.shadow-base {
    box-shadow: 0px 1px 2px 0 rgb(127 126 126 / 73%);
}
.lineheight356{
    line-height: 3.56;
}
.deleteathlet {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #ff0000;
    color: #ffffff;
    font-size: 16px;
    padding: 6px 11px;
    border-radius: 40px;
    border: 1px solid #ff0000;
}
.deleteathlet:hover{
    color: #ff0000;
    background: #ffffff;
}
.deleteathlet:hover i{
    color: #ff0000;
}
input[type="file"] {
    padding: 0px 10px 10px 0px;
    height: 36px;
}
img.uploadimage {
    width: 150px;
    padding: 10px;
    border: 1px solid #c1c1c1;
}
textarea{
    width: 100%;
    color: #333333;
    padding: 10px 11px 10px 13px;
    border-radius: 5px;
    font-size: 14px;
    outline: none !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
    vertical-align: top;
}
img.summaryimage{
    width: 110px;
    margin-bottom: 1vh;
}
.sendentry .main-list-item h6 {
    font-family: 'Amaranth';
    color: #010101;
}
.sendentry .main-list-item > div:last-child h6 {
    line-height: 1;
    width: 120px;
    text-align: center;
}
.send-footer p{
    margin-bottom: 0rem;
    font-family: 'Amaranth';
    font-size: 15px;
    text-align: center;
    color: #ff0000;
}

.photoimg{
    width: 100%;
    min-height:190px;
  }

  .photo-mark {
    position: absolute;
    bottom: 11rem;
    right: 0.2rem;
    background-color: #d71635;
    padding: 0.3rem 0.5rem;
    border-radius: 45px;
    cursor: pointer;
  }
  .photo-mark1 {
    position: absolute;
    bottom: 12rem;
    right: 0.2rem;
    background-color: #d71635;
    padding: 0.3rem 0.5rem;
    border-radius: 45px;
    cursor: pointer;
  }