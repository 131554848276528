#entrylisttable tbody tr:last-child {
    background-color: rgb(245 247 251);
}


#entrylistt .btn-sm {
    padding: 0.55rem 0.5rem 0.65rem 0.5rem;
    display: inline-block;
    text-decoration: none;
    color: #f3f3f3;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: 0.3s;
    min-width: 125px;
    font-family: 'Amaranth';
    font-size: 14px;
    line-height: 1.5;
}

button.tabbtn {
    padding: 0.75rem 1.25rem;
    border: 1px solid #cbd0df;
    font-size: 1.05rem;
    border-radius: 5px;
    background: #cbd0df;
}

button.tabbtn.active{
    background: #ff0000;
    color:#ffffff;
}
button.tabbtn h5 {
    font-family: 'Roboto';
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
}
.headd{
    background: #ff00001a;
}
.eventlistc .card-header {
    border-bottom: 1px solid #efd4d4;
}
.eventlistc .custom-switch-input{
    left: 0;
}

.eventlistc .main-content-label{
    font-weight: 600;
}

/*.eventlistd .row-sm > div{
    padding-left: 0px !important;
    padding-right: 0px !important;
}*/
.eventlistd .list-content-label {
    padding: 10px 5px;
    border-bottom: 1px solid #e3dfdf;
    margin-bottom: 0px !important;
}
.border-bottom-none {
    border-bottom: none !important;
}
.eventlistd .card-aside-column {
    min-width: 10rem;
    width: 40%;
    flex: 0 0 30%;
    background: no-repeat center/cover;
}
.eventlistd .card-body.athdetail{
    border: 1px solid #e3dfdf;
}
.athlbox .ps-0{
    padding-left: 0px !important;
}
.athlbox .pe-0{
    padding-right: 0px !important;
}
.athlbox .custom-card{
    margin-bottom: 0px;
}
.athlbox .card-options .btttn {
    padding: 9px 10px 9px 10px;
    font-family: 'Teko';
    font-size: 16px;
    text-transform: uppercase;
    background: #ff0000;
    color: #ffffff;
    line-height: 1;
    text-align: center;
    border-radius: 20px 20px;
    float: right;
    position: absolute;
    top: -10px;
    right: 0px;
    border: 2px solid #ff0000;
}

.athlbox .card-options .btttn:hover{
    background: #ffffff;
    color:#ff0000;
}

.page-header li.breadcrumb-item span{
    color: #010101;
}
.athsummbox-img{
    width: 75px;
    height: 75px;
}
.athsummbox .media .media-body h6 {
    margin-bottom: 0px;
    line-height: 1;
    color: #607d8b;
}

.athsummbox .media .media-body h6 span {
    color: #f30404;
    font-family: 'Roboto';
    font-size: 1.0rem;
    font-weight: 500;
    min-width: 50px;
    display: inline-block;
}
.athsummbox .media .media-body h6.abuid,
.athsummbox .media .media-body h6.abdob {
    font-weight: 400;
}

.athsummbox .media .media-body h6.abname {
    margin-bottom: 7px;
    padding-bottom: 2px;
    padding-top: 5px;
    border-bottom: 1px solid #e7e3e3;
}

.selectedathletes .custom-card {
    margin-bottom: 5px;
}
.selectedathletes{
    margin-bottom: 2rem;
}
.summbox .dlist-align {
    margin-bottom: 5px;
}
.summbox .step-footer span {
    font-family: "Playball", cursive;
    font-size: 0.90rem;
    color: #ff0000;
    line-height: 1.2 !important;
    font-weight: 550;
    margin-top: 10px;
    display: block;
}
.progtrckr-doing {
    width: 50% !important;
}
.progtrckr-done {
    width: 50% !important;
}
.progtrckr-todo{
    width:50% !important;
}
button#next-button {
    background: var(--primary-bg-color);
    border:none;
}
button#next-button:hover {
    background: var(--primary-bg-hover);
}
button#prev-button {
    background: var(--danger);
    border:none;
}
button#prev-button:hover {
    background: var(--secondary);
}
.searchable__controls input {
    width: 100%;
    color: #fff;
    position: relative;
    width: 100%;
    height: 44px;
    color: #333333;
    padding: 10px 11px 10px 13px;
    border-radius: 5px;
    font-size: 14px;
    outline: none !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
    vertical-align: top;
}
.athlelst .list-content-label {
    padding: 8px 5px;
    border-bottom: 1px solid #e3dfdf;
    margin-bottom: 0px !important;
    line-height: 1;
    background: #ffffff;
    text-align: start;
    font-family: "Playball", cursive;
    font-size: 1rem;
}
.athlelst .list-content-label{
    display: flex;
    width: 100%;
}
.athlelst .list-content-label span{
    display: inline-block;
    width: 40%;
}
.athlelst .list-content-label b{
    display: inline-block;
    width: 60%;
    font-weight: 500 !important;
}
.border-top{
    border-bottom: 1px solid #e3dfdf;
}
.athlelst .list-content-label span {
    color: #8f9cc0;
    font-family: 'teko';
    width: 32%;
    display: inline-block;
    border-right: 1px solid;
    margin-right: 15px;
    font-size: 1.15rem;
    line-height: 1;
}
.bg-cusimg{
    background: #e9edf726;
}
.list{
    position: absolute;
    color: #fff;
    left: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 70px;
    height: 37px;
    text-align: center;
}

.activeevent{
    text-align: center;
    background-color: #89a2af;
}

.card-footer:last-child{
    border-radius: 0%;
}

.athlelst .acc-content-label {
    padding: 8px 5px;
    margin-bottom: 0px !important;
    line-height: 1;
    background: #ffffff;
    text-align: start;
    font-family: "Playball", cursive;
    font-size: 1rem;
}

.athlelst .acc-content-label span {
    color: #8f9cc0;
    font-family: 'teko';
    width: 50%;
    display: inline-block;
    margin-right: 15px;
    font-size: 1.15rem;
    line-height: 1;
}