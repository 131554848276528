.popup-modal {
  font-weight: 400;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
}
.rep__container {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}
.rep__container p {
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}
.approve-cen {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
.can-small {
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: rgb(230, 225, 225);
  border-radius: 3px;
  outline: 0;
  border: 0;
}
.mod-body {
  padding-bottom: 0;
}
.textarea-reason {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.bpl-gap {
  margin-top: 1rem;
}
