/* .profile-group {
  line-height: 3;
} */

.profile_img {
  height: 150px;
  width: 150px;
}

.input-group.form-upload label {
  background: none;
  font-size: 1.05rem;
  padding: 0px;
  font-weight: 600;
}

.input-group.form-upload .desc {
  color: #817f7f;
  font-style: italic;
  font-size: 14px;
}

input#file {
  display: block;
  height: auto;
  height: initial !important;
  padding: 0px !important;
  font-size: 12px;
}

[type="file"]::-webkit-file-upload-button {
  background: #577381 !important;
  border: 1px solid #577381;
  border-radius: 4px 0px 0px 4px;
  color: #fff !important;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 7px 20px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 1s ease;
  font-family: "Amaranth";
}

.input-group.form-upload {
  box-shadow: 0px 0px 3px #607d8b5e;
  padding: 0.5rem;
  border: 1px solid #b3bcc591;
  border-radius: 5px;
  margin: 1rem 0;
}
.input-group.form-upload .imagecontainer {
  text-align: right;
  width: 90px;
  height: 90px;
  margin-left: auto;
  border: 1px solid #b3bcc591;
  box-shadow: 0px 0px 1px #607d8b5e;
  padding: 3px;
}
.input-group.form-upload img {
  height: 82px;
}

.btn-light{
  color: #fff;
  background-color: #6ac9d9;
  border-color: #6ac9d9;
}

.btn-light:hover{
  color: #fff;
  background-color: #6ac9d9;
  border-color: #6ac9d9;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #6ac9d9;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem #6ac9d9;
}


.btn-light.disabled,
.btn-light:disabled {
  color: #fff;
  background-color: #6ac9d9;
  border-color: #6ac9d9;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #6ac9d9;
  border-color: #6ac9d9;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #6ac9d9;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #6c757d;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem #6c757d;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #6c757d;
}

.btn {
  padding: 0.75rem;
  display: inline-block;
  text-decoration: none;
  color: #f3f3f3;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.3s;
  min-width: 150px;
  font-family: "Amaranth";
}
.btn-f {
  /* padding: 0.5rem 1.5rem 0.5rem 1.5rem; */
  /* background-color: #00bcd4; */
  padding: 0.7rem 1.5rem 0.7rem 1.5rem;
  color: #fff;
  outline: 0;
  border: none;
  border-radius: 3px;
  margin-right: 0.3rem;
  text-transform: uppercase;
}
.btn-print {
  background-color: #ca8d59;
}

.btn-print:hover{
  color: #fff;
  background-color: #ca8d59;
  border-color: #ca8d59;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #ca8d59;
}

.btn-print.focus,
.btn-print:focus {
  box-shadow: 0 0 0 0.2rem #ca8d59;
}


.btn-print.disabled,
.btn-print:disabled {
  color: #fff;
  background-color: #ca8d59;
  border-color: #ca8d59;
}

.btn-print:not(:disabled):not(.disabled).active,
.btn-print:not(:disabled):not(.disabled):active,
.show > .btn-print.dropdown-toggle {
  color: #fff;
  background-color: #ca8d59;
  border-color: #ca8d59;
}

.btn-print:not(:disabled):not(.disabled).active:focus,
.btn-print:not(:disabled):not(.disabled):active:focus,
.show > .btn-print.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #ca8d59;
}


.grp-btns {
  /* display: flex;
  justify-content: center; */
  margin-top: 1rem;
}
/* input,
select {
  width: 100%;
  height: 44px;
  padding: 10px 11px 10px 13px;
  border-radius: 5px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgb(0 0 0 / 20%);
  vertical-align: top;
} */

.profile-update {
  line-height: 3.5;
}
.comm-inv {
  margin-left: 2rem;
}
.row-cent {
  text-align: center;
}


.btn-success:hover{
  color: #fff;
  background-color: #15a552;
  border-color: #15a552;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #15a552;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem #15a552;
}


.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #15a552;
  border-color: #15a552;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #15a552;
  border-color: #15a552;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #15a552;
}
