:root {
  --primary-color: #ff0000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: "Amaranth";
  font-weight: 400;
  src: url("../../assets/fonts/Amaranth-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Amaranth";
  font-weight: 700;
  src: url("../../assets/fonts/Amaranth-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Teko";
  font-weight: 300;
  src: url("../../assets/fonts/Teko-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Teko";
  font-weight: 400;
  src: url("../../assets/fonts/Teko-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Teko";
  font-weight: 500;
  src: url("../../assets/fonts/Teko-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Teko";
  font-weight: 600;
  src: url("../../assets/fonts/Teko-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Teko";
  font-weight: 800;
  src: url("../../assets/fonts/Teko-Bold.ttf") format("truetype");
}

body {
  /*  font-family: 'Teko', sans-serif;*/
  font-family: "Amaranth", sans-serif;
  /*    font-family: 'Playball', cursive;*/
  margin: 0;
  display: grid;
  place-items: center;
  min-height: 100vh;
}
/* Global Stylings */
label {
  display: block;
  margin-bottom: 0.5rem;
}
.input-group label {
  /* width: fit-content; */
  /* position: absolute; */
  /* top: -5px; */
  /* left: 33px; */
  background: #ededed47;
  padding: 5px 15px;
  margin-bottom: 0px;
  border-top-right-radius: 18px;
}

/* input,
select {
  width: 100%;
  height: 44px;
  color: #333333;
  padding: 10px 11px 10px 13px;
  border-radius: 5px;
  font-size: 14px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgb(0 0 0 / 20%);
  vertical-align: top;
} */
input:focus-visible {
  border: 1px solid #8bc34a !important;
  outline: none;
}
input:-internal-autofill-selected {
  background-color: red !important;
  color: fieldtext !important;
}
span.errortxt {
  font-size: 0.9rem;
}
.d-none {
  display: none;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: "Teko", sans-serif;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 3rem;
  }
}

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2.5rem;
  }
}

.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 2rem;
  }
}

.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.75rem;
  }
}

.h5,
h5 {
  font-size: 1.5rem;
}

.h6,
h6 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Playball", cursive;
  font-size: 1.25rem;
}
a {
  color: #0d6efd;
  text-decoration: none;
}

a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 320px) {
  .container,
  .container-sm {
    max-width: 98%;
  }
}
@media (min-width: 375px) {
  .container,
  .container-sm {
    max-width: 98%;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1300px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1290px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  color: #0d6efd !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body,
.bg-white {
  background-color: #fff !important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  width: 100%;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 320px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.width-30 {
  width: 30%;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-70 {
  width: 70%;
}
.width-80 {
  width: 80%;
}
.width-90 {
  width: 90%;
}
.width-100 {
  width: 100%;
}

#registrationpage .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

#registrationpage .btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #007bff;
}

#registrationpage .btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

#registrationpage .btn-primary.disabled,
#registrationpage .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

#registrationpage .btn-primary:not(:disabled):not(.disabled).active,
#registrationpage .btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

#registrationpage .btn-primary:not(:disabled):not(.disabled).active:focus,
#registrationpage .btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

#registrationpage .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

#registrationpage .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #6c757d;
}

#registrationpage .btn-secondary.focus,
#registrationpage .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

#registrationpage .btn-secondary.disabled,
#registrationpage .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

#registrationpage .btn-secondary:not(:disabled):not(.disabled).active,
#registrationpage .btn-secondary:not(:disabled):not(.disabled):active,
#registrationpage .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

#registrationpage .btn-secondary:not(:disabled):not(.disabled).active:focus,
#registrationpage .btn-secondary:not(:disabled):not(.disabled):active:focus,
#registrationpage .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

#registrationpage .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

#registrationpage .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #28a745;
}

#registrationpage .btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

#registrationpage .btn-success.disabled,
#registrationpage .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #17a2b8;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #ffc107;
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #dc3545;
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #f8f9fa;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #343a40;
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.ml-auto {
  margin-left: auto;
}

.text-center {
  text-align: center;
}

/* Progressbar */
#registrationpage .progressbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  margin: 1.05rem 0 1rem;
}

#registrationpage .progressbar::before,
#registrationpage .progress {
  content: "";
  position: absolute;
  bottom: -20%;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  background-color: #dcdcdc;
  z-index: 0;
}

#registrationpage .progress {
  background-color: var(--primary-color);
  width: 0%;
  transition: 0.3s;
}

#registrationpage .progress-step {
  width: 2.1875rem;
  height: 2.1875rem;
  background-color: #dcdcdc;
  border-radius: 35px 35px 35px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: 800;
}

#registrationpage .progress-step::before {
  counter-increment: step;
  content: counter(step);
}

#registrationpage .progress-step::after {
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.5rem);
  font-size: 0.85rem;
  color: #666;
  display: none;
}

#registrationpage .progress-step-active {
  background-color: var(--primary-color);
  color: #f3f3f3;
}

/* Form */
#registrationpage .form {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 0.35rem;
  padding: 1.2rem 2rem;
  background: #ffffff;
  min-height: calc(100vh - 4vh);
}

#registrationpage .form-step {
  display: none;
  transform-origin: top;
  /* animation: animate 0.5s; */
}

#registrationpage .form-step-active {
  display: block;
}
#registrationpage .form .form-step .form-inner {
  min-height: 64.5vh;
}
#registrationpage .form .form-step .form-inner h4 {
  color: var(--primary-color);
  border-bottom: 1px solid;
  width: fit-content;
}
#registrationpage .form .form-step .form-inner h4 span {
  font-size: 1rem;
  color: #577381;
  font-family: "Amaranth";
  font-weight: 600;
}
#registrationpage .form .form-step .form-inner h4 span b {
  color: var(--primary-color);
  font-weight: 600;
}
#registrationpage .input-group {
  margin: 0.5rem 0;
}
#registrationpage .form .form-step .form-inner .input_container {
  border: 1px solid #e5e5e5;
}

#registrationpage .input-group.form-upload label {
  background: none;
  font-size: 1.05rem;
  padding: 0px;
  font-weight: 600;
}
#registrationpage .input-group.form-upload .desc {
  color: #817f7f;
  font-style: italic;
  font-size: 14px;
}
 input#file {
  display: block;
  height: auto;
  height: initial !important;
  padding: 0px !important;
  font-size: 12px;
}

 [type="file"]::-webkit-file-upload-button {
  background: #577381 !important;
  border: 1px solid #577381;
  border-radius: 4px 0px 0px 4px;
  color: #fff !important;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 7px 20px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 1s ease;
  font-family: "Amaranth";
}

[type="file"]::-webkit-file-upload-button:hover {
  background: #ff0000 !important;
  border: 1px solid #ff0000;
  color: #fff !important;
}
/*input[type="file"] {
    border: none;
    background: none;
    box-shadow: none;
    margin-top: 0.5rem;
}*/
#registrationpage .input-group.form-upload {
  box-shadow: 0px 0px 3px #607d8b5e;
  padding: 0.5rem;
  border: 1px solid #b3bcc591;
  border-radius: 5px;
  margin: 1rem 0;
}
#registrationpage .input-group.form-upload .imagecontainer {
  text-align: right;
  width: 90px;
  height: 90px;
  margin-left: auto;
  border: 1px solid #b3bcc591;
  box-shadow: 0px 0px 1px #607d8b5e;
  padding: 3px;
}
#registrationpage .input-group.form-upload img {
  height: 82px;
}
#registrationpage .radioclass input {
  width: 17px;
  height: 17px;
  border: none;
  box-shadow: none;
  font-size: 40px;
}
#registrationpage .radioclass label {
  display: inline-block;
  margin-right: 60px;
  margin-left: 10px;
}

@keyframes animate {
  from {
    transform: scale(1, 0);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

/* Button */
#registrationpage .btns-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

#registrationpage .btn,
#registrationpage input[type="submit"] {
  padding: 0.75rem;
  display: inline-block;
  text-decoration: none;
  /*  background-color: var(--primary-color);*/
  color: #f3f3f3;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.3s;
  min-width: 150px;
  font-family: "Amaranth";
}
#registrationpage input[type="submit"] {
  width: auto;
}

body#registrationpage {
  height: 100vh;
}

#registrationpage:before {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 25vw;
  background: url(../../assets/images/reg_bg.png);
  background-size: cover;
  background-position: bottom right;
  display: block;
  content: "";
  z-index: -1;
}
.registrationmain {
  width: 96%;
  margin: 10px 2% 5px;
  display: flex;
}
.registrationleft {
  width: 23%;
  display: block;
}
.registrationleft img {
  cursor: pointer;
}
.registrationright {
  /* width: calc(100% - 23%); */
  width: 100%;
}
#registrationpage .checkbox input {
  display: none;
}

#registrationpage .checkbox label {
  position: relative;
  user-select: none;
  font-family: "Teko", sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 0.8rem;
}
#registrationpage .checkbox label:before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 1px solid #607d8b;
  margin-right: 0.5rem;
  border-radius: 2px;
  transition: all 0.3s;
}

#registrationpage .checkbox.disclaimer label {
  margin-left: 30px;
  font-size: 13px;
  font-family: "Amaranth";
  line-height: 17px;
}
#registrationpage .checkbox.disclaimer label:before {
  position: absolute;
  left: -30px;
  top: 4px;
}

#registrationpage .checkbox-1 label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: -5px;
  display: inline-block;
  width: 10px;
  height: 18px;
  border: solid #607d8b;
  border-width: 0px 4px 4px 0;
  transform: rotate(45deg) scale(0);
  transition: transform 0.3s;
  z-index: 9999;
}
#registrationpage .checkbox-1.disclaimer label:after {
  left: -24px;
  top: -1px;
}
#registrationpage .checkbox-1 input:checked + label:before,
#registrationpage .checkbox-2 input:checked + label:before {
  background-color: #ffffff;
}

#registrationpage .checkbox-1 input:checked + label:after {
  transform: rotate(45deg) scale(1);
}
#registrationpage .checkbox-1.disclaimer input:checked + label:after {
  transform: rotate(45deg) scale(1);
  left: -24px;
  top: -1px;
}

#registrationpage .checkbox-2 label:after {
  content: "\2713";
  position: absolute;
  left: 1px;
  top: -2px;
  transform: rotate(10deg) scale(0);
  color: #fff;
  transition: transform 0.3s;
}

#registrationpage .checkbox-2 input:checked + label:after {
  transform: rotate(10deg) scale(0.9);
}

#registrationpage .left,
#registrationpage .right {
  display: table-cell;
  vertical-align: middle;
}

#registrationpage .left {
  width: 6em;
  min-width: 6em;
  padding-right: 1em;
}
#registrationpage .left img {
  width: 100%;
}

#registrationpage .img-holder {
  display: block;
  vertical-align: middle;
  width: 2em;
  height: 2em;
}
#registrationpage .img-holder img {
  width: 100%;
  max-width: 100%;
}

#registrationpage .file-wrapper {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
}
#registrationpage .file-wrapper:hover .btn {
  background-color: #33adff !important;
}

#registrationpage .file-wrapper input {
  cursor: pointer;
  font-size: 100px;
  height: 100%;
  filter: alpha(opacity=1);
  -moz-opacity: 0.01;
  opacity: 0.01;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
}

.registrationmain.preview div#idcard {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 0.35rem;
  padding: 1.2rem 2rem;
  background: #ffffff;
  min-height: calc(100vh - 40vh);
  /* min-height: calc(100vh - 4vh); */
  display: block;
}
.registrationmain.preview .id_main {
  width: 100%;
  display: inline-block;
}

.registrationmain.preview .registrationright .card.card_left {
  position: relative;
  height: 274px;
  width: 428px;
  /*    margin: 0 auto;*/
  background-image: url(../../assets/images/id_front.png);
  border-radius: 4px;
  /*    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 40%), 0 0 10px rgb(0 0 0 / 55%), 0 2px 10px rgb(0 0 0 / 60%);*/
  background-size: contain;
  float: left;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.registrationmain.preview .registrationright .card.card_right {
  position: relative;
  height: 274px;
  width: 428px;
  /*    margin: 0 auto;*/
  background-image: url(../../assets/images/id_back.jpg);
  border-radius: 4px;
  /*    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 40%), 0 0 10px rgb(0 0 0 / 55%), 0 2px 10px rgb(0 0 0 / 60%);*/
  background-size: contain;
  float: right;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 2px 1px;
}

.registrationmain.preview .registrationright .card.card_left .profil img {
  border: 0px solid #fff;
  box-shadow: 0 0 3px rgb(0 0 0 / 25%);
  float: left;
  margin-right: 0px;
  left: 22px;
  width: 114.5px;
  height: 122px;
  transition: all 0.3s ease-in-out;
  top: 92px;
  position: relative;
}

.registrationmain.preview .registrationright .card.card_left .area {
  position: relative;
  height: auto;
  float: left;
  width: 65%;
  top: 30px;
  left: 150px;
  text-transform: uppercase;
  color: #a5091e;
}
.registrationmain.preview .registrationright .card.card_left .area h5 {
  margin-bottom: 0px;
  height: 29px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 96%;
}
.registrationmain.preview .registrationright .card.card_left .area h6.id_dob {
  position: relative;
  top: -5px;
  left: 39px;
  width: 50%;
  margin-bottom: 0px;
}
.registrationmain.preview
  .registrationright
  .card.card_left
  .area
  h6.id_gender {
  position: relative;
  top: -5px;
  left: 65px;
  width: 50%;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.registrationmain.preview .registrationright .card.card_left .area .id_content {
  display: flex;
  width: 100%;
  top: 12px;
  position: relative;
}
.registrationmain.preview .registrationright .card.card_left .area .id_uid {
  position: relative;
  top: 27px;
  left: -30px;
}
.registrationmain.preview .registrationright .card.card_left .area .id_uid h6 {
  font-size: 1.5rem;
  color: #111111;
  margin-bottom: 0px;
  width: 50%;
}
.registrationmain.preview
  .registrationright
  .card.card_left
  .area
  .id_signature {
  width: 35%;
  position: relative;
  right: 27px;
  margin-left: auto;
  top: -23px;
}
.registrationmain.preview
  .registrationright
  .card.card_left
  .area
  .id_signature
  h6 {
  font-size: 14px;
  margin-bottom: 0px;
  position: relative;
  top: -3px;
}
.registrationmain.preview
  .registrationright
  .card.card_left
  .area
  .id_signature
  p {
  font-size: 0.6rem;
  font-style: normal !important;
  font-family: "Amaranth";
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 0px;
  position: relative;
  top: -8px;
}
.registrationmain.preview .registrationright .card.card_left .id_preview h6 {
  position: relative;
  right: 15px;
  text-align: right;
  bottom: 200px;
  color: #fff;
  text-transform: uppercase;
  width: 80%;
  float: right;
}
.registrationmain.preview .blink {
  animation: blinker 3s linear infinite;
}
@-webkit-keyframes blinker {
  0% {
    opacity: 1;
    color: #ebd831;
  }
  50% {
    opacity: 0.5;
    color: orange;
  }
  100% {
    opacity: 1;
    color: #ffffff;
  }
}

.registrationmain.preview .registrationright .card.card_left .id_preview h6 {
  /*text-transform: uppercase;
    background-image: linear-gradient(to right top, #9e9e9e, #ffc812, #ff8f2f, #00bcd4, #2196f3);
    background-size: 50% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientAnim 7.5s ease alternate infinite;
    text-shadow: 1px -1px 1px #ffffff26, 1px 1px 1px #00000012;*/
  /* background: #fff; */
}

@keyframes gradientAnim {
  to {
    background-position: 100% top;
  }
}

.registrationmain.preview .registrationright .card.card_right .id_address h6 {
  width: 65%;
  position: relative;
  left: 140px;
  top: 28px;
  font-size: 1.25rem;
  font-family: "Teko";
  line-height: 1;
  color: #a5091e;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 85px;
}
.registrationmain.preview .registrationright .card.card_right .id_qrcode img {
  border: 0px solid #fff;
  box-shadow: 0 0 3px rgb(0 0 0 / 25%);
  float: right;
  margin-right: 0px;
  left: -22px;
  width: 114.5px;
  height: 122px;
  transition: all 0.3s ease-in-out;
  top: 16px;
  position: relative;
}
.registrationmain.preview .id_fields {
  margin-top: 2rem;
}
.registrationmain.preview .id_final .id_bottom .checkbox label {
  font-family: "Amaranth";
}
.registrationmain.preview .id_final .id_bottom .checkbox-1 label:after {
  top: -3px;
  border-color: #a5091e;
}
.registrationmain.preview .id_final .id_bottom .checkbox label:before {
  border: 1px solid #a5091e;
}
.registrationmain.preview .id_final {
  position: relative;
  /* bottom: -170px; */
}
.registrationmain.preview .id_fields .btn,
.registrationmain.preview .id_fields input[type="submit"] {
  text-transform: uppercase;
  font-size: 1rem;
}
.registrationmain.preview .id_final .id_bottom {
  width: 100%;
  display: flex;
}
.registrationmain.preview .id_final .id_bottom .checkbox.checkbox-1,
.registrationmain.preview .id_final .id_bottom .id_bottombtn {
  width: 50%;
}
.registrationmain.preview .id_final .id_bottom .id_bottombtn {
  text-align: right;
}
.registrationmain.preview .id_final .id_bottom .id_bottombtn .btn {
  text-transform: uppercase;
  font-family: "Amaranth";
  font-size: 1rem;
}

@media (max-width: 576px) {
  .registrationmain {
    display: block;
  }
  .registrationmain.preview {
    width: 100%;
    margin: 1% 0%;
    display: block;
  }
  .registrationmain.preview .registrationleft {
    margin-bottom: 10px;
  }
  .registrationright .card.card_left {
    margin-bottom: 25px;
  }
  .registrationright .card.card_left,
  .registrationright .card.card_right {
    float: unset;
  }
  .registrationright .card.card_left .area .id_content {
    top: 11px;
  }
  .registrationleft {
    width: 100%;
    text-align: center;
    padding: 12px;
  }
  .registrationright {
    width: 100%;
  }
  #registrationpage .input-group label {
    width: 77%;
  }
  #registrationpage .btn,
  #registrationpage input[type="submit"] {
    /* min-width: 130px; */
    min-width: 120px;
    margin-top: 10px;
  }
  #registrationpage .input-group.form-upload .imagecontainer {
    margin-top: 10px;
    margin-right: auto;
  }
  #registrationpage .form {
    margin: 0px auto 20px;
  }
  #registrationpage .registrationmain.preview .id_fields {
    margin-top: 0rem;
  }
  .registrationmain.preview .id_final {
    bottom: -10px;
  }
  .registrationmain.preview .id_final .id_bottom {
    display: block;
  }
  .registrationmain.preview .id_final .id_bottom .checkbox.checkbox-1,
  .registrationmain.preview .id_final .id_bottom .id_bottombtn {
    width: 100%;
  }
  .registrationmain.preview .id_final .id_bottom .id_bottombtn {
    text-align: center;
  }
  .registrationmain.preview .id_final .id_bottom .id_bottombtn .btn {
    font-size: 22px;
    /* margin-top: 0px; */
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
  .registrationmain.preview .id_final .id_bottom .checkbox.checkbox-1 {
    width: 100% !important;
  }
  .registrationmain.preview .id_final .id_bottom .id_bottombtn {
    width: 100% !important;
  }
}

@media (max-width: 933px) {
  .registrationleft {
    width: 100%;
    display: block;
  }
  .registrationright {
    width: calc(100% - 0%);
  }
  .registrationmain {
    width: 96%;
    margin: 1% 2%;
    display: block;
  }
  #registrationpage:before {
    width: 50vw;
    background-position: top right;
  }
  .registrationmain.preview {
    display: block;
  }
  .registrationmain.preview .registrationleft,
  .registrationmain.preview .registrationright {
    width: 100%;
  }
  .registrationmain.preview .registrationright .card.card_left,
  .registrationmain.preview .registrationright .card.card_right {
    float: unset;
    margin: 15px auto;
  }
  div#idcard {
    padding: 1.2rem;
  }
}

@media (max-width: 1158px) {
  .registrationmain.preview {
    display: block;
    margin-left: 12px;
  }
  .registrationmain.preview .registrationleft,
  .registrationmain.preview .registrationright {
    width: 100%;
  }
  div#idcard {
    padding: 1.2rem;
  }
  .registrationmain.preview .id_fields {
    margin-top: 0.5rem;
  }
  .registrationmain.preview div#idcard {
    margin: 10px auto 20px auto;
  }
  .registrationmain.preview .id_final {
    bottom: -20px;
  }
  /*.registrationmain.preview .id_final {
    bottom: -110px;
}*/
  .registrationmain.preview .id_final .id_bottom .checkbox.checkbox-1 {
    width: 70%;
  }
  .registrationmain.preview .id_final .id_bottom .id_bottombtn {
    width: 30%;
  }
  .registrationmain.preview .id_final .id_bottom {
    margin-bottom: 20px;
  }
}

@media (max-width: 1335px) and (min-width: 1024px) {
  .registrationmain.preview .id_final {
    bottom: -120px;
  }
}


.mt-3 input {
  display: block;
  height: auto;
  height: initial !important;
  padding: 0px !important;
  font-size: 12px;
}


.mediarow{
  width: calc(100% + 30px);
}

@media (min-width: 320px) and (max-width: 370px){
  .radioclass{
    width: 240px;
  }
  .registrationmain.preview .registrationright .card.card_left,
  .registrationmain.preview .registrationright .card.card_right{
    width: 100%;
    height: 134px;
  }
  .registrationmain.preview .registrationright .card.card_left .profil img {
    left: 11px;
    width: 53.5px;
    height: 60px;
    top: 43px;
  }
  .registrationmain.preview div#idcard{
    padding: 1.2rem;
  }

  .registrationmain.preview .registrationright .card.card_left .id_preview h6{
    font-size: 1rem;
    right: 5px;
    text-align: right;
    bottom: 135px;
  }
  .registrationmain.preview .registrationright .card.card_left .area{
    left: 70px;
    top: 12px;
    width: 50%;
  }
  .registrationmain.preview .registrationright .card.card_left .area h5 ,
  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender,
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob,
  .registrationmain.preview .registrationright .card.card_right .id_address h6
  {
    font-size: 0.7rem;
  }

  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender
  {
    width: 55%;
    top: -21px;
    left: 55px
  }
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob{
    left: 23px;
    top: -21px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_uid{
    left: -10px;
    top: 0px;
    
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_uid h6{
    width: 100% !important;
    font-size: 0.7rem !important;
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_signature{
    width: 100%;
    left: 50px;
    right: 0px;
    top: -32px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature img{
    width: 50%;
    height: 10px;
    margin-left: 13px;
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_signature h6{
    font-size: 0.45rem;
    left: 15px;
    top: -9px;

  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature p{
    font-size: 0.45rem;
    left: -10px;
    top: -13px;
  }

  .registrationmain.preview .registrationright .card.card_right .id_address h6{
    left: 70px;
    top:15px
  }

  .registrationmain.preview .registrationright .card.card_right .id_qrcode img{
    left: -12px;
    width: 51.5px;
    height: 55px;
    top: -36px;
  }

  #registrationpage .checkbox label{
    font-size: 1rem;
  }
}


@media (min-width: 325px) and (max-width: 375px){
  .registrationmain.preview .registrationright .card.card_left,
  .registrationmain.preview .registrationright .card.card_right{
    width: 100%;
    height: 169px;
  }
  
  .registrationmain.preview .registrationright .card.card_left .profil img {
    left: 14px;
    width: 67px;
    height: 72px;
    top: 57px;
  }
  .registrationmain.preview div#idcard{
    padding: 1.2rem;
  }

  .registrationmain.preview .registrationright .card.card_left .id_preview h6{
    font-size: 1rem;
    right: 10px;
    text-align: right;
    bottom: 145px;
  }
  .registrationmain.preview .registrationright .card.card_left .area{
    left: 97px;
    top: 24px;
    width: 50%;
  }
  .registrationmain.preview .registrationright .card.card_left .area h5 ,
  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender,
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob,
  .registrationmain.preview .registrationright .card.card_right .id_address h6
  {
    font-size: 1rem;
  }

  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender
  {
    width: 55%;
    top: -22px;
    left: 55px
  }
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob{
    left: 18px;
    top: -22px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_uid{
    left: -20px;
    top: 0px;
    
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_uid h6{
    width: 100% !important;
    font-size: 1rem !important;
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_signature{
    width: 100%;
    left: 60px;
    right: 0px;
    top: -38px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature img{
    width: 50%;
    height: 13px;
    margin-left: 20px;
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_signature h6{
    font-size: 0.55rem;
    left: 22px;
    top: -5px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature p{
    font-size: 0.45rem;
    left: -10px;
    top: -10px;
  }

  .registrationmain.preview .registrationright .card.card_right .id_address h6{
    left: 80px;
    top:15px
  }

  .registrationmain.preview .registrationright .card.card_right .id_qrcode img{
    left: -12px;
    width: 69.5px;
    height: 68px;
    top: -22px;
  }

  #registrationpage .checkbox label{
    font-size: 1.2rem;
  }
}


@media (min-width: 380px) and (max-width: 425px){
  .registrationmain.preview .registrationright .card.card_left,
  .registrationmain.preview .registrationright .card.card_right{
    width: 100%;
    height: 201px;
  }
  .registrationmain.preview .registrationright .card.card_left .profil img {
    left: 17px;
    width: 81px;
    height: 88px;
    top: 66px;
  }

  .registrationmain.preview .registrationright .card.card_left .area h5 {
   margin-left: -17px;
  }

  .registrationmain.preview div#idcard{
    padding: 1.2rem;
  }

  .registrationmain.preview .registrationright .card.card_left .id_preview h6{
    font-size: 1rem;
    right: 10px;
    text-align: right;
    bottom: 155px;
  }
  .registrationmain.preview .registrationright .card.card_left .area{
    left: 125px;
    top: 31px;
    width: 50%;
  }
  .registrationmain.preview .registrationright .card.card_left .area h5 ,
  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender,
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob,
  .registrationmain.preview .registrationright .card.card_right .id_address h6
  {
    font-size: 1rem;
  }

  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender
  {
    width: 55%;
    top: -22px;
    left: 55px
  }
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob{
    left: 18px;
    top: -22px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_uid{
    left: -20px;
    top: 5px;
    
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_uid h6{
    width: 100% !important;
    font-size: 1rem !important;
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_signature{
    width: 100%;
    left: 60px;
    right: 0px;
    top: -38px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature img{
    width: 50%;
    height: 13px;
    margin-left: 27px;
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_signature h6{
    font-size: 0.7rem;
    left: 28px;
    top: -5px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature p{
    font-size: 0.55rem;
    left: -10px;
    top: -10px;
  }

  .registrationmain.preview .registrationright .card.card_right .id_address h6{
    left: 95px;
    top: 22px;
  }

  .registrationmain.preview .registrationright .card.card_right .id_qrcode img{
    left: -16px;
    width: 82.5px;
    height: 85px;
    top: -8px;
  }

  #registrationpage .checkbox label{
    font-size: 1.2rem;
  }
}


@media (min-width: 430px) and   (max-width: 768px){
  .registrationmain.preview .registrationright .card.card_left,
  .registrationmain.preview .registrationright .card.card_right {
    width: 100%;
    height: 235px;
  }
  .registrationmain.preview .registrationright .card.card_left .profil img {
    left: 20px;
    width: 94px;
    height: 102px;
    top: 78px;
  }
  .registrationmain.preview .registrationright .card.card_left .area h5 {
    margin-left: -23px;
   }
  .registrationmain.preview .registrationright .card.card_left .id_preview h6{
    font-size: 1.4rem;
    right: 10px;
    text-align: right;
    bottom: 220px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_uid{
    left: -49px;
    top: 13px;
    
  }
  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender
  {
    width: 55%;
    top: -12px;
    left: 35px
  }
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob{
    left: 10px;
    top: -12px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature{
    width: 100%;
    left: 40px;
    right: 0px;
    top: -38px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature h6{
    left: 70px;
    top: -5px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature img{
    width: 45%;
    height: 13px;
    margin-left: 65px;
  }
  .registrationmain.preview .registrationright .card.card_right .id_address h6{
    left: 110px;
    top: 26px;
  }

  .registrationmain.preview .registrationright .card.card_right .id_qrcode img{
    left: -21px;
    width: 94.5px;
    height: 95px;
    top: 6px;
  }
  #registrationpage .checkbox label{
    font-size: 1.3rem;
  }
}


@media (min-width: 770px) and   (max-width: 1024px){
  .registrationmain.preview .registrationright .card.card_left,
  .registrationmain.preview .registrationright .card.card_right {
    width: 100%;
    height: 420px;
  }
  .registrationmain.preview .registrationright .card.card_left .profil img {
    left: 34px;
    width: 173px;
    height: 187px;
    top: 138px;
  }
  .registrationmain.preview .registrationright .card.card_left .area{
    left: 250px;
  }
  .registrationmain.preview .registrationright .card.card_left .area h5 {
    margin-left: -23px;
   }
  .registrationmain.preview .registrationright .card.card_left .id_preview h6{
    font-size: 1.4rem;
    right: 10px;
    text-align: right;
    bottom: 220px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_uid{
    left: -49px;
    top: 58px;
    
  }
  .registrationmain.preview .registrationright .card.card_left .area
  {
    top:50px
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_content{
    left: 47px;
    top:37px
  }
  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender
  {
    width: 55%;
    top: -12px;
    left: 35px
  }
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob{
    left: 10px;
    top: -12px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature{
    width: 100%;
    left: 85px;
    font-size: 2rem;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature h6{
    left: 150px;
    top: -5px;
    font-size: 18px;
  }
  .registrationmain.preview .registrationright .card.card_left .area .id_signature img{
    width: 34%;
    height: 20px;
    margin-left: 140px;
  }
  .registrationmain.preview .registrationright .card.card_right .id_address h6{
    left: 200px;
    top: 45px;
    font-size: 1.7rem;
  }

  .registrationmain.preview .registrationright .card.card_right .id_qrcode img{
    left: -35px;
    width: 171.5px;
    height: 171px;
    top: 86px;
  }
  #registrationpage .checkbox label{
    font-size: 1.5rem;
  }

  .registrationmain.preview .registrationright .card.card_left .area .id_uid h6{
    width: 100% !important;
    font-size: 2rem !important;
  }
  .registrationmain.preview .registrationright .card.card_left .area h5 ,
  .registrationmain.preview .registrationright .card.card_left .area h6.id_gender,
  .registrationmain.preview .registrationright .card.card_left .area h6.id_dob,
  .registrationmain.preview .registrationright .card.card_right .id_address h6
  {
    font-size: 2rem;
  }
}

@media (max-width: 450px) {
  .date-field {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0.2rem;
  }
}
