@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box;
}
/***********************Common Css for all pages Start**********************/
html,
body {
  height: 100%;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
/*  font-family: 'Righteous', cursive;*/
  font-family: 'Teko', sans-serif;
  font-family: 'Amaranth', sans-serif;
/*  font-family: 'Playball', cursive;*/

}

/* body {
  background: #ff2200;
  margin: 0;
} */
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25;
}

hr:not([size]) {
  height: 1px;
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: 'Teko', sans-serif;
}

.h1,h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width:1200px) {
  .h1,h1 {
    font-size: 3rem;
  }
}

.h2,h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width:1200px) {
  .h2,h2 {
    font-size: 2.5rem;
  }
}

.h3,h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width:1200px) {
  .h3,h3 {
    font-size: 2rem;
  }
}

.h4,h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width:1200px) {
  .h4,h4 {
    font-size: 1.75rem;
  }
}

.h5,h5 {
  font-size: 1.5rem;
}

.h6,h6 {
  font-size: 1.25rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: 'Playball', cursive;
  font-size: 1.25rem;
}
a {
  color: #0d6efd;
  text-decoration: none;
}

a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]),a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: var(--bs-gutter-x,.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width:320px) {
  .container,.container-sm {
    max-width: 98%;
  }
}
@media (min-width:375px) {
  .container,.container-sm {
    max-width: 98%;
  }
}
@media (min-width:576px) {
  .container,.container-sm {
    max-width: 540px;
  }
}

@media (min-width:768px) {
  .container,.container-md,.container-sm {
    max-width: 720px;
  }
}

@media (min-width:992px) {
  .container,.container-lg,.container-md,.container-sm {
    max-width: 960px;
  }
}

@media (min-width:1200px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
    max-width: 1140px;
  }
}

@media (min-width:1300px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl {
    max-width: 1290px;
  }
}

@media (min-width:1400px) {
  .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
    max-width: 1320px;
  }
}

.text-center{
  text-align: center !important;
}
.text-left{
  text-align: left !important;
}
.text-right{
  text-align: right !important;
}
.m-0 {
  margin: 0!important;
}

.m-1 {
  margin: .25rem!important;
}

.m-2 {
  margin: .5rem!important;
}

.m-3 {
  margin: 1rem!important;
}

.m-4 {
  margin: 1.5rem!important;
}

.m-5 {
  margin: 3rem!important;
}

.m-auto {
  margin: auto!important;
}

.mx-0 {
  margin-right: 0!important;
  margin-left: 0!important;
}

.mx-1 {
  margin-right: .25rem!important;
  margin-left: .25rem!important;
}

.mx-2 {
  margin-right: .5rem!important;
  margin-left: .5rem!important;
}

.mx-3 {
  margin-right: 1rem!important;
  margin-left: 1rem!important;
}

.mx-4 {
  margin-right: 1.5rem!important;
  margin-left: 1.5rem!important;
}

.mx-5 {
  margin-right: 3rem!important;
  margin-left: 3rem!important;
}

.mx-auto {
  margin-right: auto!important;
  margin-left: auto!important;
}

.my-0 {
  margin-top: 0!important;
  margin-bottom: 0!important;
}

.my-1 {
  margin-top: .25rem!important;
  margin-bottom: .25rem!important;
}

.my-2 {
  margin-top: .5rem!important;
  margin-bottom: .5rem!important;
}

.my-3 {
  margin-top: 1rem!important;
  margin-bottom: 1rem!important;
}

.my-4 {
  margin-top: 1.5rem!important;
  margin-bottom: 1.5rem!important;
}

.my-5 {
  margin-top: 3rem!important;
  margin-bottom: 3rem!important;
}

.my-auto {
  margin-top: auto!important;
  margin-bottom: auto!important;
}

.mt-0 {
  margin-top: 0!important;
}

.mt-1 {
  margin-top: .25rem!important;
}

.mt-2 {
  margin-top: .5rem!important;
}

.mt-3 {
  margin-top: 1rem!important;
}

.mt-4 {
  margin-top: 1.5rem!important;
}

.mt-5 {
  margin-top: 3rem!important;
}

.mt-auto {
  margin-top: auto!important;
}

.me-0 {
  margin-right: 0!important;
}

.me-1 {
  margin-right: .25rem!important;
}

.me-2 {
  margin-right: .5rem!important;
}

.me-3 {
  margin-right: 1rem!important;
}

.me-4 {
  margin-right: 1.5rem!important;
}

.me-5 {
  margin-right: 3rem!important;
}

.me-auto {
  margin-right: auto!important;
}

.mb-0 {
  margin-bottom: 0!important;
}

.mb-1 {
  margin-bottom: .25rem!important;
}

.mb-2 {
  margin-bottom: .5rem!important;
}

.mb-3 {
  margin-bottom: 1rem!important;
}

.mb-4 {
  margin-bottom: 1.5rem!important;
}

.mb-5 {
  margin-bottom: 3rem!important;
}

.mb-auto {
  margin-bottom: auto!important;
}

.ms-0 {
  margin-left: 0!important;
}

.ms-1 {
  margin-left: .25rem!important;
}

.ms-2 {
  margin-left: .5rem!important;
}

.ms-3 {
  margin-left: 1rem!important;
}

.ms-4 {
  margin-left: 1.5rem!important;
}

.ms-5 {
  margin-left: 3rem!important;
}

.ms-auto {
  margin-left: auto!important;
}

.p-0 {
  padding: 0!important;
}

.p-1 {
  padding: .25rem!important;
}

.p-2 {
  padding: .5rem!important;
}

.p-3 {
  padding: 1rem!important;
}

.p-4 {
  padding: 1.5rem!important;
}

.p-5 {
  padding: 3rem!important;
}

.px-0 {
  padding-right: 0!important;
  padding-left: 0!important;
}

.px-1 {
  padding-right: .25rem!important;
  padding-left: .25rem!important;
}

.px-2 {
  padding-right: .5rem!important;
  padding-left: .5rem!important;
}

.px-3 {
  padding-right: 1rem!important;
  padding-left: 1rem!important;
}

.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}

.px-5 {
  padding-right: 3rem!important;
  padding-left: 3rem!important;
}

.py-0 {
  padding-top: 0!important;
  padding-bottom: 0!important;
}

.py-1 {
  padding-top: .25rem!important;
  padding-bottom: .25rem!important;
}

.py-2 {
  padding-top: .5rem!important;
  padding-bottom: .5rem!important;
}

.py-3 {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}

.py-4 {
  padding-top: 1.5rem!important;
  padding-bottom: 1.5rem!important;
}

.py-5 {
  padding-top: 3rem!important;
  padding-bottom: 3rem!important;
}

.pt-0 {
  padding-top: 0!important;
}

.pt-1 {
  padding-top: .25rem!important;
}

.pt-2 {
  padding-top: .5rem!important;
}

.pt-3 {
  padding-top: 1rem!important;
}

.pt-4 {
  padding-top: 1.5rem!important;
}

.pt-5 {
  padding-top: 3rem!important;
}

.pe-0 {
  padding-right: 0!important;
}

.pe-1 {
  padding-right: .25rem!important;
}

.pe-2 {
  padding-right: .5rem!important;
}

.pe-3 {
  padding-right: 1rem!important;
}

.pe-4 {
  padding-right: 1.5rem!important;
}

.pe-5 {
  padding-right: 3rem!important;
}

.pb-0 {
  padding-bottom: 0!important;
}

.pb-1 {
  padding-bottom: .25rem!important;
}

.pb-2 {
  padding-bottom: .5rem!important;
}

.pb-3 {
  padding-bottom: 1rem!important;
}

.pb-4 {
  padding-bottom: 1.5rem!important;
}

.pb-5 {
  padding-bottom: 3rem!important;
}

.ps-0 {
  padding-left: 0!important;
}

.ps-1 {
  padding-left: .25rem!important;
}

.ps-2 {
  padding-left: .5rem!important;
}

.ps-3 {
  padding-left: 1rem!important;
}

.ps-4 {
  padding-left: 1.5rem!important;
}

.ps-5 {
  padding-left: 3rem!important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw)!important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw)!important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw)!important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw)!important;
}

.fs-5 {
  font-size: 1.25rem!important;
}

.fs-6 {
  font-size: 1rem!important;
}

.fst-italic {
  font-style: italic!important;
}

.fst-normal {
  font-style: normal!important;
}

.fw-light {
  font-weight: 300!important;
}

.fw-lighter {
  font-weight: lighter!important;
}

.fw-normal {
  font-weight: 400!important;
}

.fw-bold {
  font-weight: 700!important;
}

.fw-bolder {
  font-weight: bolder!important;
}

.lh-1 {
  line-height: 1!important;
}

.lh-sm {
  line-height: 1.25!important;
}

.lh-base {
  line-height: 1.5!important;
}

.lh-lg {
  line-height: 2!important;
}

.text-start {
  text-align: left!important;
}

.text-end {
  text-align: right!important;
}

.text-center {
  text-align: center!important;
}

.text-decoration-none {
  text-decoration: none!important;
}

.text-decoration-underline {
  text-decoration: underline!important;
}

.text-decoration-line-through {
  text-decoration: line-through!important;
}

.text-lowercase {
  text-transform: lowercase!important;
}

.text-uppercase {
  text-transform: uppercase!important;
}

.text-capitalize {
  text-transform: capitalize!important;
}

.text-wrap {
  white-space: normal!important;
}

.text-nowrap {
  white-space: nowrap!important;
}

.text-break {
  word-wrap: break-word!important;
  word-break: break-word!important;
}

.text-primary {
  color: #0d6efd!important;
}

.text-secondary {
  color: #6c757d!important;
}

.text-success {
  color: #198754!important;
}

.text-info {
  color: #0dcaf0!important;
}

.text-warning {
  color: #ffc107!important;
}

.text-danger {
  color: #dc3545!important;
}

.text-light {
  color: #f8f9fa!important;
}

.text-dark {
  color: #212529!important;
}

.text-white {
  color: #fff!important;
}

.text-body {
  color: #212529!important;
}

.text-muted {
  color: #6c757d!important;
}

.text-black-50 {
  color: rgba(0,0,0,.5)!important;
}

.text-white-50 {
  color: hsla(0,0%,100%,.5)!important;
}

.text-reset {
  color: inherit!important;
}

.bg-primary {
  background-color: #0d6efd!important;
}

.bg-secondary {
  background-color: #6c757d!important;
}

.bg-success {
  background-color: #198754!important;
}

.bg-info {
  background-color: #0dcaf0!important;
}

.bg-warning {
  background-color: #ffc107!important;
}

.bg-danger {
  background-color: #dc3545!important;
}

.bg-light {
  background-color: #f8f9fa!important;
}

.bg-dark {
  background-color: #212529!important;
}

.bg-body,.bg-white {
  background-color: #fff!important;
}

/***********************Common Css for all pages end**********************/








#loginpage {
    height: 100vh;
    overflow: hidden;
    background: #fff;
    z-index: 0;
    position: relative;
}

.loginpage_logo {
    padding: 20px 10px;
}


#loginpage:before{
  position: absolute;
  top: 0;
  height: 55vh;
  width: 100%;
  background: url('../../assets/images/login_bg.jpg');
  background-size: cover;
  background-position: bottom;
  display: block;
  content: "";
  z-index: -1;
}

.loginpage_section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.loginpage_secionone,
.loginpage_seciontwo{
    max-width: 50%;
    flex: 0 0 auto;
    width: 100%;
}
.loginpage_sectionone_left {
    margin-top: 0rem;
    padding: 90px 15px 0px 15px;
    min-height: 39vh;
    align-self: center;
    vertical-align: middle;
    display: block;
}

.loginpage_sectionone_bottom .regisbtn {
    width: 260px;
    padding: 5px 10px;
    background: #EFF8FF;
    box-shadow: 0px 0px 6px #8a9195;
    border-radius: 9px;
}
.loginpage_sectionone_bottom .regisbtn:hover {
    background: #b7291f;
    cursor: pointer;
}
.loginpage_sectionone_bottom .regisbtn:hover i,
.loginpage_sectionone_bottom .regisbtn:hover h5,
.loginpage_sectionone_bottom .regisbtn:hover span{
    color: #ffffff;
}

.loginpage_sectionone_bottom .regisbtn i,
.loginpage_sectionone_bottom .regisbtn h5{
    color: #000000;
}
.loginpage_sectionone_bottom .regisbtn span{
    color: #c1c1c1;
}
.loginpage_sectionone_bottom .regisbtn i{
    font-size: 1.2rem;
}

.loginpage_bottom_button {
    display: flex;
    padding: 15px 0px;
}

.loginpage_bottom_button a{
  margin-right: 15px;
}












.loginpage_sectiontwo_form {
    padding: 0px 15px 15px 15px;
}
#loginpage form.form {
    width: 70%;
    margin: 0px auto;
    background: #ffffff;
    padding: 40px 55px;
    min-height: 65vh;
    box-shadow: 0px 0px 5px #c1c1c1;
    border: 1px solid #cccccc94;
    border-radius: 15px;
}






#loginpage .form fieldset {
  border: none;
  padding: 0;
  margin: 20px 0;
  position: relative;
}
#loginpage .form fieldset input {
  width: 100%;
  height: 48px;
  color: #333333;
  padding: 15px 40px 15px 15px;
  border-radius: 5px;
  font-size: 14px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
  vertical-align: top;
}
#loginpage .form button {
  width: 100%;
  outline: none !important;
  background: linear-gradient(-5deg, #ff0000, #ff0000);
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 3px 0px rgba(115, 136, 89, 0.2);
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

#loginpage .btn {
  display: block;
  background: #bded7d;
  color: white;
  text-decoration: none;
  margin: 20px 0 15px 0;
  padding: 15px 15px;
  border-radius: 5px;
  position: relative;
  font-size: 1.15rem;
}
#loginpage .btn::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0), 0 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
#loginpage .btn:hover::after {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 3px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}


#loginpage form fieldset::after {
    content: "";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 37px;
    font-size: 1.25rem;
    z-index: 2;
    width: 21px;
    color: rgb(0 0 0 / 32%);
    text-align: center;
}
#loginpage form fieldset label {
    margin-bottom: 5px;
    display: block;
}
#loginpage form fieldset.email::after {
  content: "";
}
#loginpage form fieldset.password::after {
  content: "";
}
/* #loginpage form h6.text-center {
    margin-bottom: 20px;
} */
.loginpage_footer {
    display: -webkit-inline-box;
    width: 100%;
}
.loginpage_footer .loginpage_howtoregis,
.loginpage_footer .copyrights {
    width: 50%;
    line-height: 4rem;
}
.loginpage_footer .copyrights{
  text-align: right;
}
.mobilescreen{
   display: none;
}
.desktopscreen{
   display: block;
}

@media (max-width:768px) {

.loginpage_sectionone_bottom .regisbtn {
    width: 140px;
}
.loginpage_sectionone_bottom .regisbtn i, .loginpage_sectionone_bottom .regisbtn h5 {
    color: #000000;
    font-size: 1.05rem;
}
#loginpage form.form {
    width: 100%;
    margin: 0px 0px 0px auto;
    background: #ffffff;
    padding: 25px 20px;
    min-height: 65vh;
    box-shadow: 0px 0px 5px #c1c1c1;
    border: 1px solid #cccccc94;
    border-radius: 15px;
}
.loginpage_sectionone_bottom .regisbtn span {
    color: #c1c1c1;
    font-size: 0.9rem;
}
.loginpage_logo {
    padding: 20px 10px 10px 10px;
}
#loginpage form h6.text-center {
    margin-bottom: 10px;
}
#loginpage .form fieldset{
  margin:10px 0px;
}
.loginpage_footer .loginpage_howtoregis, .loginpage_footer .copyrights{
  line-height: 2.5rem;
}
.loginpage_howtoregis h5 {
    font-size: 1.25rem;
}
.loginpage_howtoregis h5 i{
    font-size: 2rem !important;
}

}


@media (max-width:576px) {
.mobilescreen{
   display: block;
}
.desktopscreen{
   display: none;
}
#loginpage{
   overflow: auto;
}
.loginpage_logo{
  text-align: center;
}
.loginpage_secionone, .loginpage_seciontwo{
    max-width: 100%;
}
.loginpage_footer .loginpage_howtoregis, .loginpage_footer .copyrights
{
    width: 100%;
    text-align: center;
    line-height: 2;
}
#loginpage form.form {
    width: 100%;
    padding: 25px 25px;
    min-height: 65vh;
}
.loginpage_secionone{
    display: none;
}
.loginpage_footer {
    display: block;
    text-align: center !important;
}
.loginpage_bottom_button{
  display: block;
}
.loginpage_sectionone_bottom .regisbtn{
  margin:auto;
}


}