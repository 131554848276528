/* .profile-group {
  line-height: 3;
} */

.profile_img {
  height: 150px;
  width: 150px;
}

.input-group.form-upload label {
  background: none;
  font-size: 1.05rem;
  padding: 0px;
  font-weight: 600;
}

.input-group.form-upload .desc {
  color: #817f7f;
  font-style: italic;
  font-size: 14px;
}

input#file {
  display: block;
  height: auto;
  height: initial !important;
  padding: 0px !important;
  font-size: 12px;
}

[type="file"]::-webkit-file-upload-button {
  background: #577381 !important;
  border: 1px solid #577381;
  border-radius: 4px 0px 0px 4px;
  color: #fff !important;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 7px 20px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 1s ease;
  font-family: "Amaranth";
}

.input-group.form-upload {
  box-shadow: 0px 0px 3px #607d8b5e;
  padding: 0.5rem;
  border: 1px solid #b3bcc591;
  border-radius: 5px;
  margin: 1rem 0;
}
.input-group.form-upload .imagecontainer {
  text-align: right;
  width: 90px;
  height: 90px;
  margin-left: auto;
  border: 1px solid #b3bcc591;
  box-shadow: 0px 0px 1px #607d8b5e;
  padding: 3px;
}
.input-group.form-upload img {
  height: 82px;
}

.btn-secondary {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #17a2b8;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #10707f;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn {
  padding: 0.75rem;
  display: inline-block;
  text-decoration: none;
  color: #f3f3f3;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.3s;
  min-width: 150px;
  font-family: "Amaranth";
}

/* input,
select {
  width: 100%;
  height: 44px;
  padding: 10px 11px 10px 13px;
  border-radius: 5px;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 1px 4px rgb(0 0 0 / 20%);
  vertical-align: top;
} */

.profile-update {
  line-height: 3.5;
}
.comm-inv {
  margin-left: 2rem;
}
.kJrhuj {
  transform: translateY(-10px);
}
.reg-chk {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
}
.chk-gap {
  margin-top: 1rem;
}
.chk-horiz {
  display: flex;
  justify-content: space-between;
}
.reg__sub a {
  color: #fff;
  background-color: #6259ca;
}
.reg-radio {
  margin-right: 1rem;
}
.reg__container {
  padding: 2rem;
}
.btn-g {
  margin-left: 1rem;
  background-color: rgb(248, 86, 86) !important;
}
.btn-exp {
  background-color: #00bcd4;
  padding: 0.6rem 1.1rem;
  color: #fff;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
}
.btn-exp:hover {
  color: #fff;
}
.res-right {
  display: flex;
  justify-content: end;
}
