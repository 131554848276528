.a-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.a-flex h4 {
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif !important;
}
.ath-row {
  width: 50% !important;
}
.view-btn {
  outline: 0;
  color: #fff;
  background-color: #00bcd4;
  padding: 4px 23px;
  border-radius: 5px;
  border: 1px solid #00bcd4;
}
.rep-btn {
  color: #fff;
  background-color: #f44336;
  padding: 9px 17px;
  border: 0;
  outline: 0;
}
.ex-btn {
  color: #fff;
  background-color: #4caf50;
  padding: 9px 17px;
  border: 0;
  outline: 0;
}
.rep-btn:hover {
  color: #fff;
  background-color: #f44336;
}
.ex-btn:hover {
  color: #fff;
  background-color: #4caf50;
}
.v-end {
  text-align: end;
}

.head-container {
  display: flex;
}
.circular-a {
  width: 100%;
  height: 12.5rem;
  border: 1px solid rgb(211, 208, 208);
}
.ath-rowtwo {
  width: 50%;
  margin-left: 0.5rem;
}
.b-flex h4 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.circular-a p {
  margin: 1rem 0 0 1rem;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase;
  font-size: 1rem;
  color: #1e88e5;
}
.eve-f {
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif !important;
}
.circular-a {
  display: flex;
  flex-direction: column;
}
.cir-cont {
  height: 100%;
}
.circular-more {
  padding-right: 0.5rem;
  background-color: #fff;
  width: 100%;
  text-align: end;
}


.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 410px;
  overflow-y: auto;
  padding-left: 0;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #6e7271;
  color: #fafaf8;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
